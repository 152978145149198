<template>
    <section v-if="initialize" class="my-0 section-shaped" :class="{'section': desktop}">
        <card class="border-0">
            <div class="row justify-content-center">
                <div class="col-lg-4">
                    <register-card></register-card>
                </div>
            </div>
        </card>
    </section>
</template>
<script>
    import RegisterCard from '../components/RegisterCard'

    export default {
        name: 'Register',
        components: {
            RegisterCard,
        },
        data() {
            return {
                initialize: false,
                desktop: screen.width > 700,
            }
        },

        mounted() {
            if (this.$store.state.user_logged) {
                this.$router.push('dashboard');
            } else {
                this.initialize = true;
            }
        }
    };
</script>
<style>
    .parent.vue-tel-input {
    }
</style>